<template>
  <div>


    <el-button @click="handleClick" type="text" size="small">编辑</el-button>
    <el-button type="text" size="small" @click="deleteApplique">删除</el-button>


    <el-dialog title="编辑" :visible.sync="dialogFormVisible" >
      <el-form :model="form" label-position="left">
        <el-form-item label="人员编码" :label-width="formLabelWidth">
          <el-input v-model="form.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="用户名" :label-width="formLabelWidth">
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色" :label-width="formLabelWidth">

          <el-select v-model="form.role"  placeholder="请选择">
            <el-option value="admin"></el-option>
            <el-option value="emp"></el-option>
            <el-option value="worker"></el-option>
          </el-select>
        </el-form-item>


      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>



</template>


<style scoped>




.contain{
  width: 100%;
  height: 800px;

}
.contain .col{
  width: 100%;
  height:50px;
  background: coral;
  display: flex;
}

.contain .col .cell{
  height: 50px;
  flex: 1;
  background: aqua;
}


</style>

<script>

import axios from "axios";

export default {
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

      dialogFormVisible: false,
      form: {
      },
      formLabelWidth: '120px'
    };
  },
  methods:{
    handleClick() {
      this.dialogFormVisible = true;
      this.form = { ...this.row };// 使用解构赋值避免引用问题
    },
    //编辑接口
    submitForm() {
      axios.put(process.env.VUE_APP_BASE_API+'/api/goods/updateApplique', this.form)
          .then(response => {

            if(response.data.success == true){
              this.dialogFormVisible = false;
              this.$emit('formEdit',true);
            }else {
              this.dialogFormVisible = false;
              this.$emit('formEdit',false);
            }
          })
          .catch(error => {
            this.dialogFormVisible = false;
            console.error('请求失败:', error);
          });
    },
    //删除接口
    deleteApplique(){

      this.$confirm('此操作将删除该贴花, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form = { ...this.row };
        axios.delete(process.env.VUE_APP_BASE_API+'/api/goods/deleteApplique/'+this.form.id)
            .then(response => {

              if(response.data.success == true){

                this.$emit('formDelete',true);
              }else {
                this.dialogFormVisible = false;
                this.$emit('formDelete',false);
              }
            })
            .catch(error => {
              console.error('请求失败:', error);
            });
      }).catch(() => {

      });
    }
  }
};

</script>