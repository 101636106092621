<template>
  <div>

    <el-button @click="openDialog" type="text" size="small">编辑</el-button>


    <el-dialog :visible.sync="dialogFormVisible" title="编辑订单" width="50%">
      <el-form ref="orderForm" :model="orderForm" label-width="80px">
        <el-form-item label="订单号" :class="{ 'is-error': errors.poId }">
          <el-input v-model="orderForm.poId" @blur="validateField('poId')" disabled></el-input>
          <div v-if="errors.poId" class="error-message">{{ errors.poId }}</div>
        </el-form-item>
        <el-form-item label="接单日期">
          <el-date-picker
              v-model="orderForm.orderDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出货日期">
          <el-date-picker
              v-model="orderForm.shipmentDate"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="地区">
          <el-input v-model="orderForm.region"></el-input>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="orderForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="备注" >
          <el-input v-model="orderForm.remark"></el-input>
        </el-form-item>

        <el-form-item label="产品列表">
          <div v-for="(product, index) in orderForm.goodsItems" :key="index" style="margin-bottom: 10px;"
               >
            <div class="row">
              <div class="select" >
                <el-select v-model="product.goods" filterable placeholder="请选择"
                           :class="{ 'is-error': errGoodsList.includes(product.goods) }">
                  <el-option
                      v-for="item in goodsList"
                      :key="item.id"
                      :label="item.goods"
                      :value="item.id">
                  </el-option>
                </el-select>

              </div>
              <div class="quantity">
                <el-input-number v-model="product.goodsQuantity" :min="1" placeholder="数量"></el-input-number>
              </div>
              <div class="delete">
                <el-button type="danger" icon="el-icon-minus" @click="removeProduct(index)"></el-button>
              </div>
            </div>

          </div>
          <el-button type="primary" icon="el-icon-plus" @click="addProduct">添加产品</el-button>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取消</el-button>
      <el-button type="primary" @click="submitForm">完成编辑</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props:{
    row:{
      type:Object,
      required:true,
    }
  },

  data() {
    return {
      dialogFormVisible:false,
      orderForm: {
        poId: '',
        shipmentDate: '',
        orderDate: '',
        region: '',
        amount:'',
        remark: '',
        goodsItems: [],
      },
      goodsList:[],
      errors: {
        poId: '',
        shipmentDate: '',
        orderDate: '',
        region: '',
        amount: '',
        remark: '',
      },
      errGoodsList:[],
    };
  },
  methods: {
    validateField(field) {
      if (!this.orderForm[field]) {
        this.$set(this.errors, field, '此字段不能为空');
      } else {
        this.$set(this.errors, field, '');
      }
    },
    openDialog(){
      this.dialogFormVisible = true




      this.orderForm = JSON.parse(JSON.stringify(this.row));

      axios.get(process.env.VUE_APP_BASE_API+'/api/order/findAllGoods')
          .then(response => {
            this.goodsList = response.data.data.goodsList;

          })
          .catch(error => {
            console.error('请求失败:', error);
          });

    },
    addProduct() {
      this.orderForm.goodsItems.push({ goods: '',poId:this.orderForm.poId, goodsQuantity: 1 });
    },
    removeProduct(index) {
      this.orderForm.goodsItems.splice(index, 1);
    },
    submitOrder() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          console.log('提交的订单数据:', this.orderForm);
          this.$emit('editOrder', false);
        } else {
          console.log('表单验证失败!');
          return false;
        }
      });
    },
    submitForm() {
      axios.put(process.env.VUE_APP_BASE_API+'/api/order/editOrder', this.orderForm)
          .then(response => {
            if(response.data.success){
              this.dialogFormVisible = false;

              this.$emit("editOrder",true);
            }else {
              this.errGoodsList = response.data.data.isDeleteGoodsList;
              this.$emit('editOrder', false);
            }
          })
          .catch(error => {
            console.error('请求失败:', error);
            this.$emit('editOrder', false);
          });
    }
  }
};
</script>

<style scoped>
/* 子组件的样式 */

.row{
  width: 100%;
  display: flex;
}
.select{
  flex: 1;
  height: 50px;
}
.quantity{
  flex: 1;
  height: 50px;
  display: flex;
  justify-content: center;
}
.delete{
  flex: 1;
  height: 50px;
}

.is-error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 12px;
  height: 16px;

}
</style>
