<template>
  <div>

    <el-button @click="openDetail" type="text" size="small">详情</el-button>


    <el-dialog :visible.sync="dialogFormVisible" title="订单详情" width="50%">


      <el-table
          :data="orderTable"
          border
          style="width: 100%;margin-top: 40px">
        <el-table-column
            prop="poId"
            label="订单号"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="quantity"
            label="数量"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="shipment"
            label="出货量">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="inventory"
            label="库存">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="lack"
            label="缺数">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="scrap"
            label="报废">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="realInput"
            label="实际投入">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="rework"
            label="返工">
        </el-table-column>
        <el-table-column
            prop="process"
            label="完成度">
        </el-table-column>
      </el-table>


      <el-table
          :data="goodsTable"
          border
          style="width: 100%;margin-top: 40px">
        <el-table-column
            prop="goodsCode"
            label="产品编码"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsQuantity"
            label="数量"
        >
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsShipment"
            label="出货量">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsInventory"
            label="库存">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsLack"
            label="缺数">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="goodsScrap"
            label="报废数">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="realInput"
            label="实际投入">
        </el-table-column>
        <el-table-column
            width="80px"
            prop="rework"
            label="返工">
        </el-table-column>
        <el-table-column
            prop="process"
            label="完成度">
        </el-table-column>
      </el-table>




      <span slot="footer" class="dialog-footer">
        <div><el-button @click="dialogFormVisible = false">取消</el-button></div>
        <div><el-button type="primary" @click="submitOrder">查看头盔明细</el-button></div>


    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    row:{
      type:Object,
      required:true,
    }
  },
  data() {
    return {
      dialogFormVisible:false,
      order: {},
      orderTable:[],
      goods:{},
      goodsTable:[],
    };
  },
  methods: {
    openDetail(){

      this.dialogFormVisible = true
      this.order = JSON.parse(JSON.stringify(this.row));
      this.orderTable = [this.order]
      this.goodsTable = this.order.goodsItems;
    },

    submitOrder() {
      this.$refs.orderForm.validate((valid) => {
        if (valid) {
          console.log('提交的订单数据:', this.orderForm);
          this.$emit('update:dialogFormVisible', false);
        } else {
          console.log('表单验证失败!');
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
/* 子组件的样式 */

.row{
  width: 100%;
  display: flex;
}
.select{
  flex: 1;
  height: 50px;
}
.quantity{
  flex: 1;
  height: 50px;
}
.delete{
  flex: 1;
  height: 50px;
}
</style>
