<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">
          <div class="select-serch">
            <div style="margin-top: 15px;">
              <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
                <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px">
                  <el-option label="ID" value="1"></el-option>
                  <el-option label="产品编码" value="2"></el-option>
                  <el-option label="UID" value="3"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="tow-button">
          <div class="button">

          </div>
          <div class="button">

          </div>
        </div>

      </div>
      <div class="common-middle">
        <div class="bar"></div>
        <div class="table">
          <el-table
              :data="tableData"
              style="width: 100%"
              border>
            <el-table-column
                fixed
                prop="n"
                label="序号">
              <template slot-scope="scope">
                {{ ( scope.$index + 1).toString().padStart(2, '0') }}
              </template>
            </el-table-column>
            <el-table-column prop="id" label="ID" fixed="left"/>
            <el-table-column prop="epcId" label="UID" fixed="left"/>
            <el-table-column prop="goods" label="产品编码" fixed="left"/>
            <el-table-column prop="poId" label="PO" fixed="left"/>
            <el-table-column prop="spray" label="喷油状态"/>
            <el-table-column prop="sprayEmp" label="喷油人员"/>
            <el-table-column prop="sprayWeight" label="喷油重量"/>
            <el-table-column prop="sprayTime" label="喷油时间" />
            <el-table-column prop="sprayQc" label="喷油质检"/>
            <el-table-column prop="applique" label="贴花状态"/>
            <el-table-column prop="appliqueEmp" label="贴花人员"/>
            <el-table-column prop="appliqueQc" label="贴花质检"/>
            <el-table-column prop="appliqueTime" label="贴花时间" :formatter="formatDate"/>
            <el-table-column prop="assemble" label="装配状态"/>
            <el-table-column prop="assembleEmp" label="装配人员"/>
            <el-table-column prop="assembleWeight" label="装配重量"/>
            <el-table-column prop="assembleTime" label="装配时间" :formatter="formatDate"/>
            <el-table-column prop="assembleQC" label="装配质检"/>
            <el-table-column prop="encasement" label="装箱状态"/>
            <el-table-column prop="encasementEmp" label="装箱人员"/>
            <el-table-column prop="encasementTime" label="装箱时间" :formatter="formatDate"/>
            <el-table-column prop="status" label="状态" fixed="right"/>
          </el-table>
        </div>

      </div>
      <div class="common-foot">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="400"
            style="margin-left: auto;margin-top: 15px;margin-right: 15px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'SubPage2',
  created() {
    this.fetchData();
  },
  methods:{
    fetchData() {
      this.$axios.get('http://106.52.201.224:8080/api/banding/find')
          .then(response => {
            this.tableData = response.data.data.orderItems;
          })
          .catch(error => {
            console.error('Error:', error);
          });
    }
  },
  components: {},
  data() {
    return {
      tableData: [],
      input3:'',
      select:'',
    }
  }
}
</script>

<style scoped>
/* 样式可以根据需要添加 */

.custom-table .el-table {
  font-size: 12px; /* 调整表格字体大小 */
}

.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;


  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  background: #4B6EA8;
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;

}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}


.common .common-middle .table{
  width: 100%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;

}
.common .common-foot{
  background: #4B6EA8;
  width: 100%;
  display: flex;
}

</style>
